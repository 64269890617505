import { Show, createSignal, createEffect, useContext } from 'solid-js';
import { Icon } from '@troon/icons';
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogActions,
	DialogAction,
	Form,
	ToggleSwitch,
	Label,
	FieldDescription,
} from '@troon/ui';
import { useSubmission } from '@solidjs/router';
import { gql, mutationAction, ReservationUserState, useMutation } from '../../../../graphql';
import { ReservationCtx } from '../../../../providers/reservation';
import { createFragment } from '../../../../graphql/create-fragment';
import { Player } from './player';
import { InvitePlayers } from './invite-players';
import { ShareGuestPass } from './guest-pass';
import { InvitePlayersInfo } from './invite-players-info';
import type { FragmentType, ReservationUser } from '../../../../graphql';

type Props = {
	userSlot: number;
	user: ReservationUser;
	canModify: boolean;
	isHost?: boolean;
	availableGuestPass: FragmentType<typeof ShareGuestPassFragment> | undefined;
	guestPassDiscount?: string;
};

export function ReservationPlayerSlot(props: Props) {
	const data = useContext(ReservationCtx)!;
	const [editOpen, setEditOpen] = createSignal(false);
	const [removeOpen, setRemoveOpen] = createSignal(false);

	const handleRemoveUser = useMutation(removeUser);
	const handleAddGuestPass = useMutation(addGuestPass);
	const handleDeactivateGuestPass = useMutation(deactivateGuestPass);

	const removeSub = useSubmission(handleRemoveUser);
	const addSub = useSubmission(handleAddGuestPass);
	const deactivateSub = useSubmission(handleDeactivateGuestPass);

	createEffect(() => {
		if (addSub.result?.data || deactivateSub.result?.data || removeSub.result?.data) {
			setTimeout(() => {
				setEditOpen(false);
			}, 500);
		}
	});

	const availableGuestPass = createFragment(ShareGuestPassFragment, props, 'availableGuestPass');

	return (
		<div class="flex flex-wrap items-center justify-between gap-x-4 overflow-hidden">
			<Player user={props.user} isHost={props.isHost} userSlot={props.userSlot} />

			<Show when={props.canModify}>
				<div class="flex grow flex-row flex-wrap items-center justify-end gap-2 justify-self-end">
					<Show when={!props.user.user}>
						<Dialog key="reservation-invite-players">
							<DialogTrigger size="sm" appearance="tertiary" class="size-fit shrink grow-0">
								<Icon name="share" /> Invite
							</DialogTrigger>
							<DialogContent
								header={
									<>
										<Icon name="user-add" class="text-lg text-brand" /> Invite players
									</>
								}
								headerLevel="h3"
							>
								<div class="flex flex-col gap-8">
									<div>
										<InvitePlayersInfo />
									</div>
									<InvitePlayers />
								</div>
							</DialogContent>
						</Dialog>
					</Show>

					<Show
						when={
							props.user.user &&
							props.user.state === ReservationUserState.Accepted &&
							props.availableGuestPass &&
							!props.user.guestPass &&
							!props.isHost &&
							!props.user.user?.troonAccessProductType
						}
					>
						<Dialog key="reservation-share-guest-pass">
							<DialogTrigger size="sm" appearance="tertiary" class="size-fit shrink grow-0">
								<Icon name="user-card-id" /> Share Guest Pass
							</DialogTrigger>
							<DialogContent noPadding>
								<ShareGuestPass reservationUser={props.user} />
							</DialogContent>
						</Dialog>
					</Show>

					<Show when={!props.isHost && props.user.user}>
						{(user) => (
							<Dialog key="reservation-edit-player" onOpenChange={setEditOpen} open={editOpen()}>
								<DialogTrigger size="sm" appearance="tertiary" class="size-fit shrink grow-0">
									<Icon name="edit-pencil-01" />
									<span class="sr-only">
										Modify {user().firstName} {user().lastName}
									</span>
								</DialogTrigger>
								<DialogContent header="Edit Player" headerLevel="h3">
									<div class="flex flex-col gap-6">
										<div class="flex justify-between border-b border-neutral pb-4">
											<Player user={props.user} />
											<Dialog key="reservation-remove-player" open={removeOpen()} onOpenChange={setRemoveOpen}>
												<DialogTrigger action="danger" appearance="tertiary" size="sm" class="size-fit shrink grow-0">
													Remove player
												</DialogTrigger>
												<DialogContent header="Remove Player" headerLevel="h4">
													<div class="flex flex-col gap-6">
														<p class="text-xl font-semibold">
															Are you sure you want to remove {user().firstName} {user().lastName} from your tee time
															reservation?
														</p>
														<Show when={props.user.guestPass}>
															<p>
																If this user accepted one of your Guest Passes, it will be returned to your account.
															</p>
														</Show>
														<p class="font-semibold">
															This slot will still be available if you want to invite someone else.
														</p>
														<DialogActions>
															<Form action={handleRemoveUser} document={removeUserMutation}>
																<input type="hidden" name="userId" value={user().id} />
																<input type="hidden" name="reservationUserId" value={props.user.id} />
																<DialogAction action="danger" type="submit">
																	Remove player
																</DialogAction>
															</Form>
															<DialogAction type="button" appearance="secondary" onClick={() => setRemoveOpen(false)}>
																Cancel
															</DialogAction>
														</DialogActions>
													</div>
												</DialogContent>
											</Dialog>
										</div>
										<Show
											when={
												props.user.state === ReservationUserState.Accepted &&
												((!props.user.user?.troonAccessProductType && props.user.guestPass) ?? availableGuestPass)
											}
											fallback={
												<DialogActions>
													<DialogAction type="button" appearance="secondary" onClick={() => setEditOpen(false)}>
														Cancel
													</DialogAction>
												</DialogActions>
											}
										>
											{(guestPass) => (
												<Form
													action={props.user.guestPass ? handleDeactivateGuestPass : handleAddGuestPass}
													document={addGuestPassToUserMutation}
												>
													<input type="hidden" name="guestPassId" value={guestPass().id} />
													<input type="hidden" name="reservationUserId" value={props.user.id} />
													<input type="hidden" name="__reservationId" value={data()?.reservation.id} />
													<ToggleSwitch
														name="__toggle"
														reverse
														class="items-start gap-24"
														checked={!!props.user.guestPass}
													>
														<div>
															<Label>Guest Pass</Label>
															<FieldDescription>
																Save your guest <b>{props.guestPassDiscount}</b> by sharing a Guest Pass! This is an
																exclusive benefit included with your Troon Access membership.
															</FieldDescription>
														</div>
													</ToggleSwitch>

													<DialogActions>
														<DialogAction type="submit">Save changes</DialogAction>
														<DialogAction type="button" appearance="secondary" onClick={() => setEditOpen(false)}>
															Cancel
														</DialogAction>
													</DialogActions>
												</Form>
											)}
										</Show>
									</div>
								</DialogContent>
							</Dialog>
						)}
					</Show>
				</div>
			</Show>
		</div>
	);
}

const ShareGuestPassFragment = gql(`fragment ShareGuestPass on GuestPass {
	id
	status
}`);

const removeUserMutation = gql(`mutation removeUserFromReservation($reservationUserId: String!) {
  removeUserFromReservation(reservationUserId: $reservationUserId) { id }
}`);

const removeUser = mutationAction(removeUserMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'removeUser',
		transform: (data) => ({
			userId: data.get('userId') as string,
			reservationUserId: data.get('reservationUserId') as string,
		}),
	},
});

const addGuestPassToUserMutation = gql(`mutation addGuestPassToUser($reservationUserId: String!) {
  addGuestPassToUser(reservationUserId: $reservationUserId) { id }
}`);

const deactivateGuestPassMutation = gql(`mutation deactivateGuestPass($guestPassId: String!) {
  deactivateGuestPass(guestPassId: $guestPassId) { reservation { id } }
}`);

const addGuestPass = mutationAction(addGuestPassToUserMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'addGuestPass',
		transform: (data) => ({
			reservationId: data.get('__reservationId') as string,
			reservationUserId: data.get('reservationUserId') as string,
		}),
	},
});

const deactivateGuestPass = mutationAction(deactivateGuestPassMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'removeGuestPass',
		transform: (data) => ({
			reservationId: data.get('__reservationId') as string,
			reservationUserId: data.get('reservationUserId') as string,
		}),
	},
});
